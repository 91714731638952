import * as React from "react";
import { cva, type VariantProps } from 'class-variance-authority';
import {cn} from '../utils/classes';

export const inputVariants = cva(
  "px-1.5 block w-full rounded-md py-0.5 sm:text-sm sm:leading-6 bg-slate-700 text-white shadow-sm ring-1 ring-inset ring-gray-500 placeholder:text-gray-400 border border-gray-800 focus:ring-2 focus:ring-inset focus:ring-orange-600",
  {
    variants: {
      variant: {
        default: "",
        outlined: "",
        filled: "dark:bg-slate-700 bg-pewter-50 dark:text-white text-pewter-950 dark:border-gray-800 border-transparent dark:focus:ring-orange-600 focus:ring-jade-500 dark:shadow-sm shadow-none dark:ring-1 ring-0",
      },
    },
    defaultVariants: {
      variant: "default"
    },
  }
)

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
