import {ArrowDownTrayIcon, ArrowLeftIcon, MagnifyingGlassPlusIcon,} from "@heroicons/react/24/outline";
import {useReactTable} from "@tanstack/react-table";
import * as React from "react";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Link, useParams} from "wouter";
import FilterContext from "../Contexts/FilterContext";
import {getDefaultReactTableConfig} from "../Data";
import useDataApi from "../hooks/useFetchDataApi";
import usePrint from "../hooks/usePrint";
import useReducedColumns from "../hooks/useReducedColumns";
import {Button} from "../ui/Button";
import {Card, CardContent, CardHeader, CardTitle} from "../ui/Card";
import DataTable from "../ui/DataTable";
import {Dialog, DialogContent} from "../ui/Dialog";
import {downloadCsv, millionsAndBillionsFormatter} from "../utils";
import {classNames} from "../utils/classes";
import ChartErrorState from "./ChartErrorState";
import ChartLoader from "./ChartLoader";
import ChartPie from "./ChartPie";
import DropdownChartOptions from "./DropdownChartOptions";
import PrintSelectedFilters from "./PrintSelectedFilters";

const METRIC_NAME = 'dpe';

export default function Dpe({title, className}) {
  const {filterState, printingMarketReport} = useContext(FilterContext);
  const [config, setConfig] = useState({});
  const [chartData, setChartData] = useState([]);
  const [data, setData] = useState([]);
  const [transformErrorMessage, setTransformErrorMessage] = useState();
  const {metric} = useParams();
  const [printContainerRef, printChart] = usePrint();

  const [fetchDataApi, loading, apiErrorMessage] = useDataApi();

  const errorMessage = useMemo(() => apiErrorMessage || transformErrorMessage, [apiErrorMessage, transformErrorMessage]);

  useEffect(() => {
    getData();
  }, [filterState]);

  const columns = useReducedColumns('years', 'lob', (value) => millionsAndBillionsFormatter(value, 4));

  const table = useReactTable({
    ...getDefaultReactTableConfig(),
    columns,
    data: data,
  });

  const getData = useCallback(() => {
    return fetchDataApi('/api/data/charts/dpe', 'POST')
      .then(({data}) => {
        setTransformErrorMessage(undefined);

        setConfig(data.reduce((acc, row) => {
          acc[row.line_name] = {
            label: row.line_name,
          };

          return acc;
        }, {}));

        const transformedChartData = Object.values(data.reduce((acc, row) => {
          if (row.line_name === 'Totals') return acc;

          const total = parseInt(row.data.reduce((acc, d) => acc + parseInt(d.total_direct_premiums_earned), 0));

          acc[row.line_name] = {
            lob: row.line_name,
            total: total,
          };

          return acc;
        }, {}));

        const transformedTableData = data.reduce((acc, row) => {
          const tRow = {
            lob: row.line_name,
          };

          filterState.years.forEach((y) => {
            tRow[y.toString()] = 0;
          });

          row.data.forEach((d) => {
            tRow[d.year.toString()] = parseInt(d.total_direct_premiums_earned);
          });

          acc.push(tRow);
          return acc;
        }, []);

        setData(transformedTableData);
        setChartData(transformedChartData);
      });
      // .catch((error) => {
      //   setTransformErrorMessage('We could not process the data.');
      // });
  }, [filterState]);

  function renderChart() {
    return (
      <div className={classNames(loading ? 'opacity-50' : '', 'mx-auto')}>
        <ChartLoader show={loading} />
        {
          errorMessage ? (
            <ChartErrorState message={errorMessage} reload={getData}/>
          ) : (
            <>
              <ChartLoader show={loading} />
              {data?.length ? <ChartPie data={chartData} config={config} className={classNames('max-h-[350px] w-full', className)} /> : null}
            </>
          )
        }
      </div>
    );
  }

  function doDownload() {
    downloadCsv(METRIC_NAME, data);
  }

  return (
    <Card ref={printContainerRef} className='dark:bg-gray-700 bg-white print:bg-white print:shadow-none print:text-black print:mx-auto print:rounded-none print:border-none flex flex-col p-0 h-full'>
      <PrintSelectedFilters show={!printingMarketReport} title={`${title} report`} />
      <CardHeader className="p-3">
        <CardTitle className='flex print:justify-center justify-between items-center w-full text-md'>
          <div>{title}</div>
          <div className='flex items-center space-x-4 print:hidden'>
            <Link to={`/data/metric/${METRIC_NAME}`}>
              <MagnifyingGlassPlusIcon className='h-5'/>
            </Link>
            <Dialog open={metric === METRIC_NAME}>
              <DialogContent disableClose className='w-full h-full flex flex-col justify-start max-w-full !rounded-none border-none'>
                <div className='flex flex-col justify-between overflow-y-auto overflow-x-hidden w-full md:mx-auto relative'>
                  <div className='flex items-center justify-between sticky z-50 top-0 bg-gray-800 w-full py-4 px-4'>
                    <div className='flex items-center justify-start space-x-5'>
                      <Link to='/data'>
                        <ArrowLeftIcon className='h-5'/>
                      </Link>
                      <strong>{title}</strong>
                    </div>
                    <Button onClick={doDownload} variant='link' className='flex items-center space-x-2 p-0' size='sm'>
                      <ArrowDownTrayIcon className='h-5'/>
                    </Button>
                  </div>
                  <div className='space-y-14 p-5 lg:w-1/2 w-full mx-auto'>
                    {renderChart()}
                    {loading ? null : <DataTable className='h-auto' table={table}/>}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            <DropdownChartOptions loading={loading} onPrint={printChart} metricName={METRIC_NAME} onDownload={doDownload}/>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent
        className={classNames(loading ? 'opacity-50' : '', 'p-2 relative h-full transition-opacity min-h-[250px] print:mx-auto')}>
        {renderChart()}
      </CardContent>
    </Card>
  )
}
