import React, {useMemo, useState, useEffect, useRef, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";
import {useLocation} from "wouter";
import AppLoadNavigationManager from "./AppLoadNavigationManager";
import ChatFloater from "./ChatFloater";
import MiniSidebar from "./Dashboard/MiniSidebar";
import EventManager from "./EventManager";
import withSocket from "./hoc/withSocket";
import useAppLocation from "./hooks/useAppLocation";
import useGetMe from "./hooks/useGetMe";
import useOnlineBeacon from "./hooks/useOnlineBeacon";
import useSocket, {useSocketEvent} from "./hooks/useSocket";
import MobileNav from "./Nav/MobileNav";
import NavigationToggle from "./NavigationToggle";
import {setChannels, updateChannel} from "./reducers/channelsSlice";
import {setSpaces} from "./reducers/spacesSlice";
import {setLabels, setTags} from "./reducers/tagsSlice";
import {setFoundMatchedProfile, setLoadingMatchedProfile, setUser} from "./reducers/userSlice";
import SocketDebugger from "./SocketDebugger";
import UserSetupBanner from "./UserSetup/UserSetupBanner";
import DashboardContext from './Contexts/DashboardContext';
import {Panel, PanelGroup, PanelResizeHandle} from 'react-resizable-panels';
import Sidebar from './Dashboard/Sidebar';
import UserContext from "./Contexts/UserContext";
import useUserProfileCompletionCalculation from "./UserSetup/useUserProfileCompletionCalculation";
import {requestNotificationPermission} from "./utils/Notify";
import {DEFAULT_TOAST_CONFIG} from "./utils/toast";
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';

const SIDEBAR_PANEL_MIN_SIZE = 24;

function Dashboard({children}) {
  const [socket, connected] = useSocket();
  const me = useSelector((state) => state.user);
  const [showSidebar, setShowSidebar] = useState(false)
  const [, navigate] = useLocation();
  const dispatch = useDispatch();
  const sidebarRef = useRef(null);
  const [activeApp, {activeSpace}] = useAppLocation();
  const [profileCompletionLoaded, {percentage: profileCompletionPercentage}] = useUserProfileCompletionCalculation();

  useOnlineBeacon();

  const getUserData = useGetMe();

  const getMe = useCallback(() => {
    getUserData().then((me) => {
      handleFindMatchingProfile(me);
    });
  }, [getUserData]);

  useEffect(() => {
    if (activeApp !== 'chat' || activeSpace || !me.hasLoaded) return;

    navigate('/chat/all');
  }, [activeSpace, me.user.hasLoaded]);

  useEffect(requestNotificationPermission, []);

  useEffect(() => {
    if (!connected || !socket) return;

    getMe();
    socket.emit('user:unread_message_total');
  }, [socket, connected]);

  useSocketEvent('tag:added', getMe);
  useSocketEvent('tag:updated', getMe);
  useSocketEvent('tag:removed', getMe);

  useSocketEvent('channels:invitation', ({channel}) => {
    getMe().then(() => {
      toast.info((
        <div className="space-y-2">
          <p className="text-slate-200 leading-tight text-sm">You have been added to a new conversation</p>
          <strong className="block text-slate-200 text-sm">{channel.slug}</strong>
        </div>
      ), DEFAULT_TOAST_CONFIG);
    });
  });

  useSocketEvent(`updated_user_state`, ({spaces, channels}) => {
    if (spaces) dispatch(setSpaces(spaces));
    if (channels) dispatch(setChannels(channels));
  });

  useSocketEvent('channel:updated', ({updatedChannel}) => {
    dispatch(updateChannel({channelId: Number.parseInt(updatedChannel.id), updatedChannel}));
    getMe();
  });

  useSocketEvent('new_channel', () => {
    toast.info(`You have a new conversation!`, DEFAULT_TOAST_CONFIG)
    getMe();
  });

  useSocketEvent('user:me', () => {
    getMe();
  });

  useSocketEvent('profile:claim:approved', ({newChannelCount}) => {
    toast.info(`Your profile claim request has been approved! ${newChannelCount ? `${newChannelCount} message requests have been added!` : ''}`, {
      ...DEFAULT_TOAST_CONFIG,
      autoClose: false,
      onClick: () => navigate('/'),
    });

    getMe();
  });

  const hasNav = useMemo(() => ['chat', 'switchboard'].includes(activeApp));

  useEffect(() => {
    if (!sidebarRef.current) return;

    if (!hasNav) {
      sidebarRef.current.collapse();
    } else {
      sidebarRef.current.resize(SIDEBAR_PANEL_MIN_SIZE);
    }
  }, [sidebarRef, activeApp, hasNav]);

  function handleFindMatchingProfile(me) {
    if (!me) return;

    dispatch(setLoadingMatchedProfile(true));

    if (me.user.user_profile_id || me.user.setup_has_declined_found_user || me.user.setup_has_provided_profile_info) {
      dispatch(setLoadingMatchedProfile(false));
      return
    }

    fetch('/api/profiles/matches', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    })
    .then(resp => resp.json())
    .then(({profile}) => {
      if (!profile) return;

      dispatch(setFoundMatchedProfile(profile));
    })
    .finally(() => {
      dispatch(setLoadingMatchedProfile(false));
    });
  }

  function toggleShowSidebar(open) {
    setShowSidebar(typeof open === "boolean" ? open : !showSidebar);
  }

  return (
    <DashboardContext.Provider value={{
      showSidebar,
      toggleShowSidebar,
    }}>
      <AppLoadNavigationManager />
      <div className='h-full flex flex-col justify-between'>
        <UserSetupBanner loaded={profileCompletionLoaded} percentage={profileCompletionPercentage} />
        {
          hasNav ? (
            <div className='lg:hidden shrink py-3 pl-3 border-b border-gray-600'>
              <NavigationToggle />
            </div>
          ) : null
        }
        <div className='h-full w-full grow overflow-y-auto'>
          <EventManager />
          <UserContext.Provider value={me}>
            <PanelGroup className='h-full grow' autoSaveId="appPanel" direction="horizontal">
              {
                hasNav ? (
                  <>
                    <Panel className='hidden lg:block' defaultSize={SIDEBAR_PANEL_MIN_SIZE} minSize={15} ref={sidebarRef}>
                      <div className='flex justify-between grow w-full h-full relative dark:bg-gray-900 bg-jade-800'>
                        <MiniSidebar />
                        <Sidebar />
                      </div>
                    </Panel>
                    <PanelResizeHandle className='lg:block hidden dark:w-[2px] dark:bg-gray-600 w-[1px] bg-gray-300 hover:bg-orange-500 transition-colors'/>
                    <Panel>
                      <div className='bg-transparent grow overflow-auto h-full relative'>
                        {children}
                      </div>
                    </Panel>
                  </>
                ) : (
                  <>
                    <div className='flex justify-between h-full relative dark:bg-gray-900 bg-jade-800'>
                      <MiniSidebar/>
                    </div>
                    <div className='bg-transparent grow overflow-auto h-full relative'>
                      {children}
                    </div>
                  </>
                )
              }
            </PanelGroup>
            <SocketDebugger/>
            <ChatFloater />
            <ToastContainer
              bodyClassName="bg-slate-900"
              toastClassName="p-2 bg-slate-900"
              progressClassName="bg-orange-500"
              position="bottom-center"
              autoClose={false}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover
              theme="dark"
            />
          </UserContext.Provider>
        </div>
        <div className='shrink'>
          <MobileNav/>
        </div>
      </div>
    </DashboardContext.Provider>
  );
}

export default withSocket(Dashboard);
