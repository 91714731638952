import {Cell, Pie, PieChart} from "recharts";
import {ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent} from "../ui/Chart";
import {millionsAndBillionsFormatter} from "../utils";
import {classNames} from "../utils/classes";
import {selectColorOnGradient} from "../utils/colors";

export default function ChartPie({data, config, className}) {
  return (
    <ChartContainer
      config={config}
      className={classNames('', className)}
    >
      <PieChart>
        <Pie data={data} dataKey="total" nameKey="lob">
          {
            data.map((entry, idx) => (
              <Cell key={`cell-${idx}`} fill={selectColorOnGradient(idx / data.length)} />
            ))
          }
        </Pie>
        <ChartTooltip
          cursor={false}
          content={(
            <ChartTooltipContent valueFormatter={(value) => millionsAndBillionsFormatter(value, 3)} hideLabel className="dark:bg-gray-800 bg-white" />
          )}
        />
        <ChartLegend
          content={<ChartLegendContent nameKey="lob" />}
          className="-translate-y-2 flex-wrap gap-2 [&>*]:basis-1/4 [&>*]:justify-center whitespace-nowrap max-h-[100px] overflow-y-auto"
        />
      </PieChart>
    </ChartContainer>
  )
}
