import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon, BellIcon, BellSlashIcon,
  Cog6ToothIcon,
  EllipsisVerticalIcon,
  StarIcon, TagIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import {Fragment, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {useCopyToClipboard} from "react-use";
import useIsUserAdmin from "../hooks/useIsUserAdmin";
import useSocket from "../hooks/useSocket";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "../ui/Dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "../ui/Dropdown";
import {Sheet, SheetContent, SheetHeader, SheetTitle} from "../ui/Sheet";
import {DEFAULT_TOAST_CONFIG} from "../utils/toast";
import ArchiveChatForm from "./Settings/ArchiveChatForm";
import SettingsForm from "./Settings/SettingsForm";

export default function ChatHeaderChannelTitleDropdownMenu({channelId, showTags, toggleShowTags}) {
  const [socket] = useSocket();
  const channel = useSelector(state => state.channels[channelId]);
  const [showArchiveDialog, setShowArchiveDialog] = useState(false);
  const [showChannelSettings, setShowChannelSettings] = useState(false);
  const [, copy] = useCopyToClipboard();
  const isAdmin = useIsUserAdmin();

  function toggleStarred(channel) {
    socket.emit('channels_users:update', {channelId: channel.id, starred: !channel.starred}, ({success, starred}) => {
      if (!success) {
        return toast.error(`The conversation could not be ${starred ? 'starred' : 'unstarred'}.`, DEFAULT_TOAST_CONFIG);
      }

      toast.success(`Your conversation has been ${starred ? 'starred.' : 'unstarred!'}`, DEFAULT_TOAST_CONFIG);
    });
  }

  function toggleMute(channel) {
    socket.emit('user:state:toggle_mute', {channelId: channel.id, muted: channel.muted}, ({success, muted}) => {
      if (!success) {
        return toast.error(`The conversation could not be ${muted ? 'muted' : 'unmuted'}.`, DEFAULT_TOAST_CONFIG);
      }

      toast.success(`Your conversation has been ${muted ? 'muted.' : 'unmuted!'}`, DEFAULT_TOAST_CONFIG);
    });
  }

  return (
    <>
      <Sheet open={showChannelSettings} onOpenChange={setShowChannelSettings}>
        <SheetContent>
          <SheetHeader className='p-5'>
            <div className='flex items-center space-x-2'>
              <UsersIcon className='h-5' />
              <SheetTitle>{channel.slug}</SheetTitle>
            </div>
          </SheetHeader>
          <div>
            <SettingsForm onClose={() => setShowChannelSettings(false)} channel={channel} />
          </div>
        </SheetContent>
      </Sheet>
      <Dialog open={showArchiveDialog} onOpenChange={setShowArchiveDialog}>
        <DialogContent className='p-5'>
          <DialogHeader>
            <DialogTitle>Archive conversation</DialogTitle>
          </DialogHeader>
          <ArchiveChatForm channelId={channel.id} />
        </DialogContent>
      </Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <EllipsisVerticalIcon className="h-7 w-7 dark:hover:text-gray-100 hover:text-gray-500 transition cursor-pointer" />
        </DropdownMenuTrigger>
        <DropdownMenuContent side='bottom' align='end'>
          <DropdownMenuItem className='space-x-2' onClick={() => toggleStarred(channel)}>
            {
              channel.starred ? (
                <>
                  <StarIcon className='h-5 w-5 fill-amber-500' />
                  <span>Remove favorite</span>
                </>
              ) : (
                <>
                  <StarIcon className='h-5 w-5' />
                  <span>Add favorite</span>
                </>
              )
            }
          </DropdownMenuItem>
          <DropdownMenuItem className='space-x-2' onClick={toggleShowTags}>
            {
              showTags ? (
                <>
                  <TagIcon className='h-5 w-5' />
                  <span>Hide labels</span>
                </>
              ) : (
                <>
                  <TagIcon className='h-5 w-5' />
                  <span>Show labels</span>
                </>
              )
            }
          </DropdownMenuItem>
          <DropdownMenuItem disabled={channel.archived} className='space-x-2' onClick={() => setShowArchiveDialog(true)}>
            {
              channel.archived ? (
                <ArchiveBoxXMarkIcon className='h-5 w-5 opacity-50' />
              ) : (
                <ArchiveBoxIcon className='h-5 w-5' />
              )
            }
            <span>Archive</span>
          </DropdownMenuItem>
          <DropdownMenuItem className='space-x-2' onClick={() => toggleMute(channel)}>
            {
              channel.muted ? (
                <>
                  <BellIcon className='h-5 w-5' />
                  <span>Unmute</span>
                </>
              ) : (
                <>
                  <BellSlashIcon className='h-5 w-5' />
                  <span>Mute</span>
                </>
              )
            }
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem className='space-x-2' onClick={() => {
            setShowChannelSettings(true);
          }}>
            <Cog6ToothIcon className='h-5 w-5' />
            <span>Settings</span>
          </DropdownMenuItem>
          {
            isAdmin ? (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem className='space-x-2' onClick={() => copy(channel.id)}>
                  Copy Chat ID
                </DropdownMenuItem>
              </>
            ) : null
          }
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
