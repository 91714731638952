import {Area, AreaChart, CartesianGrid, XAxis, YAxis} from "recharts";
import {ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent} from "../ui/Chart";
import {millionsAndBillionsFormatter} from "../utils";

export default function AreaChartComponent({data, config, formatter = millionsAndBillionsFormatter, xAxisDataKey, className, title}) {
  return (
    <ChartContainer config={config} className={className}>
      <AreaChart
        accessibilityLayer
        data={data}
        margin={{
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={xAxisDataKey}
          tickLine={false}
          axisLine={false}
          tickMargin={8}
          tick={{fill: 'white'}}
          tickFormatter={(value) => value.slice(0, 4)}
        />
        <YAxis
          tickFormatter={(value) => formatter(value, 1)}
          tick={{fill: 'white'}}
        />
        <ChartTooltip
          cursor={false}
          content={(
            <ChartTooltipContent className="dark:bg-gray-800 bg-white" valueFormatter={(value) => {
              return formatter(value, 3);
            }} indicator="dot"  />
          )}
        />
        {
          Object.values(config).map(({label, color}) => {
            return (
              <Area
                dataKey={label}
                type="natural"
                fill={color}
                fillOpacity={0.15}
                stroke={color}
              />
            )
          })
        }
        <ChartLegend content={<ChartLegendContent/>} />
      </AreaChart>
    </ChartContainer>
  );
}
