import React, { useState } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "./Tooltip";

interface MoreListProps {
  value?: string[];
  truncateAfter?: number;
}

const MoreList = (props: MoreListProps) => {
  const { value, truncateAfter } = props;

  const [open, setOpen] = useState(false);

  const handleTriggerClick = (event) => {    
    event.stopPropagation();
    setOpen(prev => !prev);
  };

  if (!value || value.length === 0) {
    return null;
  }

  let firstItem = value[0];
  if (truncateAfter && firstItem.length > truncateAfter) {
    firstItem = `${firstItem.substring(0, truncateAfter)}...`;
  }

  const remainingItems = value.slice(1);

  return (
    <span>
      <span>{firstItem}</span>
      {remainingItems.length > 0 && (
        <Tooltip open={open} delayDuration={999999} onOpenChange={setOpen}>
          <TooltipTrigger asChild>
            <button
              className='ml-2 hover:underline'
              onClick={handleTriggerClick}
            >
              {`+${remainingItems.length} more`}
            </button>
          </TooltipTrigger>
          <TooltipContent
            side='bottom'
            onPointerDownOutside={(event) => {
              event.preventDefault();
              setOpen(false);
            }}
          >
            <ul className='flex flex-col gap-1 list-disc max-w-[400px] px-4 py-1'>
              {value.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </TooltipContent>
        </Tooltip>
      )}
    </span>
  );
};

export default MoreList;