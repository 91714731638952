import {ChevronDownIcon, TagIcon,} from "@heroicons/react/24/outline";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useClickAway, useKeyPressEvent, useSearchParam} from 'react-use';
import useKeyboardJs from "react-use/lib/useKeyboardJs";
import {useLocation} from "wouter";
import ChatLabel from "../Channel/ChatLabel";
import {setFilterLabels, setFilterText} from "../reducers/channelGridSlice";
import {Button} from "../ui/Button";
import {classNames} from "../utils/classes";
import {getRgbString} from "../utils/colors";

export default function Search({classes: className}) {
  const [showList, setShowList] = useState(false);
  const [labelFilter, setLabelFilter] = useState('');
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [selectedFilterBackspaceIdx, setSelectedFilterBackspaceIdx] = useState(-1);
  const [selectedFilters, setSelectedFilters] = useState(new Map());
  const labels = useSelector(state => state.user?.user?.labels);
  const {filterText, filterLabels} = useSelector(state => state.channelGrid);
  const [query, setQuery] = useState(filterText || '');
  const ref = useRef(null);
  const textSearchInputRef = useRef(null);
  const tagFilterInputRef = useRef(null);
  const urlQuery = useSearchParam('query');
  const urlLabels = useSearchParam('labels');
  const [isPressingShortcut] = useKeyboardJs('ctrl + k');
  const [currentLocation, navigate] = useLocation();
  const dispatch = useDispatch();

  useKeyPressEvent('ArrowLeft', handleLeftArrowKeyFocus, () => {});
  useKeyPressEvent('ArrowRight', handleRightArrowKeyFocus, () => {});
  useKeyPressEvent('ArrowUp', handleFocusedIndexUp, () => {});
  useKeyPressEvent('ArrowDown', handleFocusedIndexDown, () => {});
  useKeyPressEvent('Enter', handleSelectFilter, () => {});
  useKeyPressEvent('Backspace', handleBackSpace, () => {});
  useKeyPressEvent('Escape', () => setShowList(false), () => {});

  useEffect(() => {
    if (!filterLabels) return;

    setSelectedFilters(labels.filter(l => filterLabels.includes(l.id)).reduce((acc, label) => {
      acc.set(label.uuid, label);
      return acc;
    }, new Map()));
  }, [filterLabels]);

  useEffect(() => {
    if (!showList) return;

    tagFilterInputRef.current?.focus();
  }, [showList, tagFilterInputRef.current]);

  useEffect(() => {
    focusedIndex === -1 ? tagFilterInputRef.current?.focus() : tagFilterInputRef.current?.blur();
  }, [focusedIndex, tagFilterInputRef]);

  useEffect(() => {
    if (!isPressingShortcut) return;

    textSearchInputRef.current?.focus();
    setShowList(true);
  }, [textSearchInputRef, isPressingShortcut]);

  useEffect(() => {
    dispatch(setFilterText(urlQuery || ''));
    setQuery(urlQuery || '');

    if (urlLabels && labels) {
      const queryLabelUuids = urlLabels?.split(',');
      const selectedLabelIds = labels.filter(l => queryLabelUuids.includes(l.uuid)).map(l => l.id);
      dispatch(setFilterLabels(selectedLabelIds));
    } else {
      dispatch(setFilterLabels([]));
    }
  }, [urlQuery, urlLabels, labels]);

  useClickAway(ref, () => {
    setShowList(false);
  });

  const filteredLabels = useMemo(() => {
    if (!labels.length || !labelFilter.length) return [];

    return labels.filter(({name, uuid}) => name.toLowerCase().includes(labelFilter) && !selectedFilters.has(uuid));
  }, [labelFilter, labels, selectedFilters]);

  function handleLeftArrowKeyFocus(event) {
    if (event.target.selectionStart !== 0) {
      return;
    }

    if (selectedFilterBackspaceIdx > -1) {
      setSelectedFilterBackspaceIdx(selectedFilterBackspaceIdx - 1)
    } else {
      setSelectedFilterBackspaceIdx(selectedFilters.size - 1);
    }
  }

  function handleRightArrowKeyFocus(event) {
    if (event.target.selectionStart !== 0) return;

    const proposedNewIdx = selectedFilterBackspaceIdx + 1;
    const newIdx = Math.min(proposedNewIdx, selectedFilters.size - 1);

    if (proposedNewIdx === selectedFilters.size) {
      setSelectedFilterBackspaceIdx(-1);
      return;
    }

    setSelectedFilterBackspaceIdx(newIdx);

    if (selectedFilterBackspaceIdx > -1) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function handleBackSpace(event) {
    if (selectedFilterBackspaceIdx < 0) return;

    event.preventDefault();

    const [filterUuidToRemove] = Array.from(selectedFilters).find((filterEntry, idx) => idx === selectedFilterBackspaceIdx);

    const newSelectedFilters = new Map(selectedFilters);
    newSelectedFilters.delete(filterUuidToRemove);

    setSelectedFilters(newSelectedFilters);
    setSelectedFilterBackspaceIdx(-1);
  }

  function handleSelectFilter() {
    if (focusedIndex < 0) return;

    const selectedLabel = filteredLabels[focusedIndex];

    const newSelectedFilters = new Map(selectedFilters);
    newSelectedFilters.set(selectedLabel.uuid, selectedLabel);
    setSelectedFilters(newSelectedFilters);

    tagFilterInputRef.current?.focus();
    setLabelFilter('');
  }

  function handleFocusedIndexUp() {
    setFocusedIndex(Math.max(focusedIndex - 1, -1));
  }

  function handleFocusedIndexDown() {
    if (!filteredLabels.length) return;
    setFocusedIndex(focusedIndex + 1 > filteredLabels.length ? filteredLabels.length - 1 : focusedIndex + 1);
  }

  function reset() {
    setSelectedFilters(new Map());
    setLabelFilter('');
    setQuery('');
    navigate(currentLocation);
  }

  function search() {
    const term = query;
    const tagIds = Array.from(selectedFilters).map(([, {uuid}]) => uuid);

    const searchParams = new URLSearchParams();
    if (term) searchParams.set('query', term);
    if (tagIds.length) searchParams.set('labels', tagIds.join(','));

    dispatch(setFilterText(term || ''));
    dispatch(setFilterLabels(tagIds || ''));
    navigate(`?${searchParams.toString()}`);
  }

  return (
    <div ref={ref} className={classNames(className, "w-full sticky z-50 transition-height px-3 shrink dark:bg-slate-700 bg-pewter-50 mx-auto rounded-md py-1 dark:text-white dark:shadow-sm dark:ring-1 ring-inset dark:ring-gray-500 placeholder:text-gray-400 border dark:border-gray-800 border-white focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6")}>
      <div className='flex items-center w-full justify-between space-x-2'>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            search();
          }}
          className='grow'
        >
          <input
            ref={textSearchInputRef}
            tabIndex={1}
            type="search"
            name="search"
            id="search"
            value={query}
            className='bg-transparent outline-0 p-1 text-sm focus:border-0 focus:border-transparent w-full px-0 focus:ring-0'
            onChange={({target: {value}}) => setQuery(value)}
            placeholder="Filter by conversation name..."
          />
        </form>

        <button className='w-8 flex justify-end' onClick={() => {
          setShowList(!showList);
          tagFilterInputRef.current?.focus();
        }}>
          <ChevronDownIcon className={classNames(showList ? 'rotate-180' : '', 'transition-all h-5 w-5')}/>
        </button>

        {
          selectedFilters.size ? (
            <div className='flex items-center space-x-0.5'>
              <span className='bg-fuse-orange rounded-full text-xs px-2'>{selectedFilters.size}</span>
              <TagIcon className={classNames('transition-all h-4 w-4')}/>
            </div>
          ) : null
        }
      </div>

      <div className={classNames(showList ? 'block' : 'hidden', 'transition-height relative text-sm my-2')}>
        <label className='text-gray-300 text-xs'>Labels</label>
        <div className='bg-slate-500 rounded flex flex-wrap p-1.5 h-auto'>
          {
            Array.from(selectedFilters).map(([, filter], selectedFilterIdx) => {
              return (
                <ChatLabel
                  key={`selected_filters_${filter.uuid}`}
                  fill
                  tagId={filter.id}
                  icon={false}
                  onClickRemove={() => {
                    const newSelectedFilters = new Map(selectedFilters);
                    newSelectedFilters.delete(filter.uuid);
                    setSelectedFilters(newSelectedFilters);
                  }}
                  className={classNames(selectedFilterBackspaceIdx === selectedFilterIdx ? 'ring-2 ring-white ring-offset-1' : '', 'truncate bg-slate-700 rounded whitespace-nowrap flex items-center space-x-1 px-1.5 py-1.5 mr-2')}
                />
              )
            })
          }
          <input
            tabIndex={2}
            ref={tagFilterInputRef}
            value={labelFilter}
            onFocus={() => setFocusedIndex(-1)}
            className='grow px-0 focus:ring-0 text-white placeholder-white text-sm border-0 h-8 bg-transparent'
            onChange={({target: {value}}) => {
              setLabelFilter(value.toLowerCase());
              setFocusedIndex(-1);
            }}
            placeholder='Add a label'
          />
        </div>
        <div className='my-2 overflow-y-auto max-h-40'>
          {
            filteredLabels && filteredLabels.map((label, idx) => {
              return (
                <div
                  key={`label_selection_${label.uuid}`}
                  onClick={() => {
                    const newSelectedFilters = new Map(selectedFilters);
                    newSelectedFilters.set(label.uuid, label);
                    setSelectedFilters(newSelectedFilters);
                    setLabelFilter('');
                  }}
                  className={classNames(idx === focusedIndex ? 'bg-gray-500' : '', 'p-2 hover:bg-gray-500 transition-colors rounded flex items-center space-x-1')}
                >
                  <span className='h-3 w-3 rounded-full' style={{backgroundColor: getRgbString(label.color)}}></span>
                  <span>{label.name}</span>
                </div>
              )
            })
          }
        </div>
        <div className='flex justify-end items-center'>
          <Button tabIndex={4} variant='ghost' onClick={reset}>Reset</Button>
          <Button tabIndex={3} onClick={() => search()}>Search</Button>
        </div>
      </div>
    </div>
  );
}
