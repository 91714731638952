import {
  ChevronDownIcon, ChevronLeftIcon, UserCircleIcon, XMarkIcon,
} from "@heroicons/react/24/outline";
import {useContext, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useParams} from "wouter";
import ChannelContext from "../Contexts/ChannelContext";
import Tippy from '@tippyjs/react';
import {useActiveChannelMembersSelector} from "../selectors/channelSelectors";
import {classNames} from "../utils/classes";
import ChatHeaderChannelTitleDropdownMenu from "./ChatHeaderChannelTitleDropdownMenu";
import ChatLabel from "./ChatLabel";

export default function ChatMessagesHeader() {
  const {
    shrinkable,
    setShowMessageList,
    showMessageList,
    channelId,
    isFloating,
    onClose,
  } = useContext(ChannelContext);
  const [showTags, setShowTags] = useState(false);
  const channel = useSelector(state => state.channels[channelId]);
  const {user} = useSelector(state => state.user);
  const channelMembers = useActiveChannelMembersSelector(channelId);
  const memberStatuses = useSelector(state => state.memberStatuses);
  const {activeSpaceId} = useParams();

  const isDm = channelMembers.length === 2;
  const isParty = channelMembers.length > 2;
  const otherUserId = isDm && memberStatuses ? channelMembers.find(memberId => memberId !== user.id) : null;
  const otherUser = memberStatuses[otherUserId];
  const channelName = isDm && !channel.slug ? `${otherUser.first_name} ${otherUser.last_name}` : channel.slug;

  return (
    <>
      <div className="border-b dark:border-gray-500 border-pewter-200 dark:bg-gray-800 bg-white">
        <div className={`${classNames(showTags ? '' : 'pb-2.5', '[&>svg]:text-gray-300 [&>svg]:h-7 p-2 pb-0 flex-1 w-full dark:text-gray-100 text-jade-950 font-bold text-lg flex items-center justify-between dark:bg-gray-800 bg-white')}`}>
          <div className="text-gray-500 cursor-pointer flex space-x-2 items-center">
            {shrinkable ? <ChevronDownIcon className={classNames(!showMessageList ? '-rotate-90' : '', 'transition h-7')} onClick={() => setShowMessageList(!showMessageList)} /> : null }
            {!isFloating ? (
              <Link to={`/${activeSpaceId}`} asChild>
                <ChevronLeftIcon role='button' className="lg:hidden text-gray-300" aria-hidden="true"/>
              </Link>
            ) : null}
          </div>
          <div className="flex items-center space-x-2 truncate px-2 w-full">
            <div className="truncate flex flex-col justify-center h-10">
              <Tippy content={(
                <div className="space-y-1">
                  {
                    channel.members && Object.values(channel.members).map(({id, first_name: firstName, last_name: lastName}) => {
                      return (
                        <div key={`members_list_${id}_${channelId}`}>{firstName} {lastName}</div>
                      );
                    })
                  }
                </div>
              )} placement='bottom' className='truncate'>
                <div className='flex items-center space-x-2'>
                  {
                    isParty ? (
                      <div className='relative mr-2'>
                        <UserCircleIcon className='h-6 bg-gray-800 rounded-full relative z-10' />
                        <UserCircleIcon className='h-6 bg-gray-800 rounded-full absolute left-2.5 top-0 z-0' />
                      </div>
                    ) : (
                      <UserCircleIcon className='h-6' />
                    )
                  }
                  <span className="text-md font-semibold leading-1">{channelName}</span>
                </div>
              </Tippy>
            </div>
          </div>
          <ChatHeaderChannelTitleDropdownMenu channelId={channelId} showTags={showTags} toggleShowTags={() => setShowTags(!showTags)} />
          {isFloating ? <XMarkIcon className='border-l border-gray-700 ml-2 pl-3' onClick={onClose} /> : null}
        </div>
        {
          showTags ? (
            <div className="flex space-x-1 mt-2 overflow-x-auto px-1 mb-1 relative">
              {channel.tags.length ? null : <span className="italic text-sm">No tags... Try adding some?</span>}
              {
                channel.tags.map(tag => (
                  <span key={`channel_header_tags_${tag.id}`}>
                    <ChatLabel className="h-5 text-xs" tagId={tag.id} fill />
                  </span>
                ))
              }
            </div>
          ) : null
        }
      </div>
    </>
  )
}
