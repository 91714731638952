import {ArrowDownIcon, XMarkIcon} from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDispatch, useSelector} from "react-redux";
import {useIntersection, useScrolling} from "react-use";
import ChatMessagesHeader from "./Channel/ChatMessagesHeader";
import CompanyInfoSlideover from "./Channel/CompanyInfoSlideover";
import Editor from './Channel/Editor';
import Message from './Channel/Message';
import MessageDateSeparator from "./Channel/MessageDateSeparator";
import TypingIndicator from "./Channel/TypingIndicator";
import UserInfoSlideover from "./Channel/UserInfoSlideover";
import ChannelContext from './Contexts/ChannelContext';
import useLockedMemo from "./hooks/useLockedMemo";
import useSocket, {useSocketEventWithFilter} from './hooks/useSocket';
import MessagesPlaceholder from "./MessagesPlaceholder.jsx";
import {
  appendManyMessages,
  prependManyMessages,
  setChannelLockToBottom,
  setManyMessages,
  setMaxSeenMessageThunk,
  setReadStatuses
} from "./reducers/channelsSlice";
import {getChannelMembersThunk} from "./reducers/membersSlice";
import Spinner from "./Spinner";
import UserAvatar from "./UserAvatar";
import {classNames} from './utils/classes';
import DateFormatter from "./utils/DateFormatter";

function InfinityLoader({bottom = false, loading = false}) {
  if (loading) {
    return (
      <div className="flex items-center justify-center py-4 font-bold border-t dark:border-gray-600 border-pewter-200">
        <Spinner className="h-5 w-5 mr-2" />
        <span>Loading more messages...</span>
      </div>
    );
  }

  return (
    <div className="text-md font-bold py-4 flex items-center justify-center border-t dark:border-gray-600 border-pewter-200">
      <div className="scale-75"><ArrowDownIcon className={classNames(!bottom ? 'rotate-180' : '', 'h-5 w-5 mr-2')} /></div>
      <div className="-ml-1.5">Keep scrolling to load</div>
    </div>
  );
}

export default function Chat({
  channelId,
  slug: name,
  isFloating = false,
  shrinkable = false,
  startAtMessageId,
  onClose,
}) {
  const messageListBottomRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const infiniteScrollContainerRef = useRef(null);
  const channelContainerRef = useRef(null);
  const userReadReceiptRef = useRef(null);
  const newMessagesSeparatorRef = useRef(null);
  const maxSeenMessageIdRef = useRef(-1);
  const channelContainerIntersection = useIntersection(channelContainerRef, {threshold: 0.25});
  const isScrollingContainer = useScrolling(channelContainerRef);
  const messages = useSelector(state => state.channels[channelId]?.messages || []);
  const readStatuses = useSelector(state => state.channels[channelId]?.readStatuses || {});
  const maxSeenMessage = useSelector(state => state.channels[channelId]?.maxSeenMessage || -1);
  const membersMap = useSelector(state => state.channels[channelId]?.members || {});
  const channel = useSelector(state => state.channels[channelId] || {});
  const {user} = useSelector(state => state.user);
  const [startingReadReceipts, setStartingReadReceipts] = useState();
  const [canLoad, setCanLoad] = useState(false);
  const [lockToBottom, setLockToBottom] = useState(false);
  const [focused, setFocused] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [initScrollMessageId, setInitScrollMessageId] = useState(startAtMessageId);
  const [allMessagesHaveLoaded, setAllMessagesHaveLoaded] = useState(false);
  const [initialScrollComplete, setInitialScrollComplete] = useState(false);
  const [loadingCurrentMessages, setLoadingCurrentMessages] = useState(true);
  const [showUnreadMessagePill, setShowUnreadMessagePill] = useState(true);
  const [hasLoadedCurrentMessages, setHasLoadedCurrentMessages] = useState(false);
  const [showMessageList, setShowMessageList] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [showCloseOptions, setShowCloseOptions] = useState(false);
  const [noNewerMessages, setNoNewerMessages] = useState(false);
  const [showUserInfoSlideover, setShowUserInfoSlideover] = useState(null);
  const [showCompanyInfoSlideover, setShowCompanyInfoSlideover] = useState(null);
  const [attachmentUuids, setAttachmentUuids] = useState([]);
  const [startingUnreadMessages, setStartingUnreadMessages] = useState();
  const {unreadMessagesByChannelId} = useSelector(state => state.global);
  const [canScroll, setCanScroll] = useState(null);
  const [socket, connected] = useSocket();
  const dispatch = useDispatch();
  const newMessagesSepIntersection = useIntersection(newMessagesSeparatorRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });

  useEffect(() => {
    if (startingReadReceipts || !Object.values(readStatuses).length) return;

    setStartingReadReceipts(readStatuses);
    setStartingUnreadMessages(unreadMessagesByChannelId[channelId]);
  }, [readStatuses, startingReadReceipts, channelId]);

  useEffect(() => {
    if (!newMessagesSeparatorRef.current || !newMessagesSepIntersection) return;

    if (showUnreadMessagePill && !newMessagesSepIntersection?.isIntersecting) return;

    setShowUnreadMessagePill(false);
  }, [newMessagesSeparatorRef, newMessagesSepIntersection, showUnreadMessagePill]);

  useEffect(() => {
    setCanLoad(!!channelContainerIntersection?.isIntersecting && !isScrollingContainer);
  }, [channelContainerIntersection, isScrollingContainer]);

  useEffect(() => {
    if (!socket || !connected || !canLoad) return;

    dispatch(getChannelMembersThunk({channelId}));
  }, [socket, connected, canLoad]);

  useEffect(() => {
    dispatch(setChannelLockToBottom({channelId, lockToBottom}))
  }, [lockToBottom]);

  useEffect(() => {
    const {scrollHeight, clientHeight} = infiniteScrollContainerRef.current.el;
    setCanScroll(scrollHeight !== clientHeight);
  }, [messages]);

  useSocketEventWithFilter('read_update', ['channelId', channelId], ({readStatuses}) => {
    dispatch(setReadStatuses({channelId, userId: user.id, readStatuses}));
  });

  useSocketEventWithFilter('channel:reload', ['channelId', channelId], () => {
    dispatch(getChannelMembersThunk({channelId}));
  });

  useEffect(() => {
    Object.keys(readStatuses).forEach((messageId) => {
      const foundReadStatus = readStatuses[messageId].find(({id}) => id === user.id);
      if (foundReadStatus && !initScrollMessageId) {
        setInitScrollMessageId(messageId);
      }
    });
  }, [readStatuses, user]);

  useEffect(() => {
    if (!infiniteScrollContainerRef.current?.el) return;

    function onScroll (event) {
      if (event.target.scrollTop === 0 && messages.length) {
        setShowLoading(true);
        setTimeout(() => {
          socket.emit('get_messages', {channel_id: channelId, range: [messages[0].id, 0, 5]}, ({messages, readStatuses}) => {
            infiniteScrollContainerRef.current.el.scrollTo({top: -55, behavior: 'smooth'});

            setShowLoading(false);

            if (!messages.length) setNoNewerMessages(true);

            dispatch(messages.length ? setManyMessages({channelId, messages}) : appendManyMessages({channelId, messages}));
            dispatch(setReadStatuses({channelId, readStatuses, userId: user.id}));
          });
        }, 1000);
      }
    }

    infiniteScrollContainerRef.current.el.addEventListener('scroll', onScroll);

    return () => {
      infiniteScrollContainerRef.current?.el?.removeEventListener('scroll', onScroll);
    }
  }, [infiniteScrollContainerRef, initialScrollComplete, messages, showLoading, noNewerMessages]);

  // Init get messages
  useEffect(() => {
    if (!canLoad) return;

    getCurrentMessages(startAtMessageId);
  }, [socket, connected, user, canLoad]);

  // Detect the intersection of messages in the list
  useEffect(() => {
    if (!messages.length || !messageListBottomRef.current) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        setLockToBottom(entry.intersectionRatio === 1);
      });
    }, {
      root: null,
      rootMargin: '0px',
      threshold: [0.0, 0.5, 1.0],
    });

    observer.observe(messageListBottomRef.current);

    return () => observer.disconnect();
  }, [messages?.length, messageListBottomRef, messagesContainerRef]);

  const initNewMessageSepMessageId = useLockedMemo(() => {
    if (!messages.length || !startingUnreadMessages) return;

    return messages.find(m => checkUserReadReceiptForUser(m))?.id || -1;
  }, [startingReadReceipts, messages]);

  // Get more messages in inf scroll up
  function getMoreMessages() {
    setShowLoading(true);

    socket.emit('get_messages', {
      range: [messages.slice(-1)[0].id, -10, -1],
      channel_id: channelId,
    }, ({messages: pastMessages}) => {
      pastMessages = pastMessages.slice(2);

      if (!pastMessages.length) {
        setAllMessagesHaveLoaded(true);
        return;
      }

      // Slow the user down, in the future we should just debounce...
      setTimeout(() => {
        setShowLoading(false);
        dispatch(prependManyMessages({channelId, messages: pastMessages}));
      }, 1000);
    });
  }

  // Scroll to the bottom when new messages come in and we're locked to the bottom of the screen.
  useEffect(() => {
    // When we get a new message and we're locked to the bottom of the screen, scroll to see the new message
    if (lockToBottom) scrollToMessagesBottom(messageListBottomRef.current);
  }, [messages?.length, lockToBottom]);

  // When messages changes, scroll to the bottom of the channel message list given some conditions
  const refCallback = useCallback((node) => {
    if (messages?.length && !initialScrollComplete && node && messagesContainerRef.current) {
      scrollToMessagesBottom(node);
    }
  }, [messages]);

  const handleLastSeenDebounced = useMemo(() => debounce(() => {
    dispatch(setMaxSeenMessageThunk({id: channelId, maxSeenMessage: maxSeenMessageIdRef.current}));
  }, 500), []);

  function getCurrentMessages(startAtMessageId, withRange) {
    if (!socket || !user) return;

    const range = startAtMessageId ? [startAtMessageId, -5, 5] : null;

    setLoadingCurrentMessages(true);

    socket.emit('get_messages', {channel_id: channelId, range}, ({messages, readStatuses}) => {
      dispatch(messages.length ? setManyMessages({channelId, messages}) : appendManyMessages({channelId, messages}));
      dispatch(setReadStatuses({channelId, readStatuses, userId: user.id}));
      setLoadingCurrentMessages(false);
      setHasLoadedCurrentMessages(true);
    });
  }

  // Handle scroll to bottom
  function scrollToMessagesBottom(anchor) {
    if (!anchor) return;

    const scrollComplete = debounce(() => {
      infiniteScrollContainerRef.current.el.removeEventListener('scroll', onScroll);

      if (!initialScrollComplete) {
        setInitialScrollComplete(true);
      }
    }, 100);

    function onScroll(_event) {
      scrollComplete();
    }

    // if the channel cannot scroll because there are not enough messages,
    // set the scroll to complete so that we can log the last message seen
    if (infiniteScrollContainerRef.current.el.scrollHeight <= infiniteScrollContainerRef.current.el.clientHeight && hasLoadedCurrentMessages) {
      setInitialScrollComplete(true);
    }

    infiniteScrollContainerRef.current.el.addEventListener('scroll', onScroll);
    infiniteScrollContainerRef.current.el.scrollTo({
      top: anchor.offsetTop + anchor.clientHeight - infiniteScrollContainerRef.current.el.clientHeight,
      block: 'start',
      behavior: 'smooth'
    });
  }

  function getMember(userId) {
    const member = membersMap[userId];
    return member || {};
  }

  function onIntersecting(message) {
    maxSeenMessageIdRef.current = Math.max(maxSeenMessageIdRef.current, message.id);

    handleLastSeenDebounced();
  }

  function checkUserReadReceiptForUser(message) {
    if (!message || !startingReadReceipts) return false;

    return !!startingReadReceipts[message.id]?.find(s => s.id === user.id);
  }

  function scrollToNewMessagesSeparator() {
    setShowUnreadMessagePill(false);

    infiniteScrollContainerRef.current.el.scrollTo({
      top: newMessagesSeparatorRef.current.offsetTop - newMessagesSeparatorRef.current.clientHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  if (!user) return null;

  const userMember = getMember(user.id);
  const starred = channel.starred;
  const channelIsArchived = channel.archived;
  const channelLocked = !!userMember.removed_from_channel_at_message_id;
  const showEditor = channelLocked || channelIsArchived;

  return (
    <ChannelContext.Provider value={{
      lockToBottom,
      shrinkable,
      showMessageList,
      setShowMessageList,
      setShowCloseOptions,
      showCloseOptions,
      channelId,
      name,
      onClose,
      isFloating,
      attachmentUuids,
      setAttachmentUuids,
      setShowSettings,
      showSettings,
      setShowUserInfoSlideover,
      setShowCompanyInfoSlideover,
      channelLocked,
      channelIsArchived,
    }}>
      <div
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        className={classNames(starred ? 'ring-yellow-600' : null, isFloating && showMessageList ? '' : 'h-full', focused ? 'ring-green-500' : 'dark:ring-gray-500 ring-pewter-200', `w-full rounded-sm transition-all bg-gray-800 ring-2 text-gray-500 shadow-xl`)}
        ref={channelContainerRef}
      >
        <div className="relative h-full overflow-x-hidden">
          <UserInfoSlideover show={!!showUserInfoSlideover} userData={showUserInfoSlideover} />
          <CompanyInfoSlideover show={!!showCompanyInfoSlideover} companyData={showCompanyInfoSlideover} />
          <div className="flex flex-col justify-between dark:bg-gray-700 bg-white relative h-full">
            <ChatMessagesHeader />
            <div className="absolute left-0 w-full top-12 dark:bg-gray-800 bg-gray-200 z-30 flex items-center justify-center">
              <TypingIndicator />
            </div>
            {
              showMessageList && (
                <>
                  <MessagesPlaceholder messages={messages} hasLoaded={hasLoadedCurrentMessages} loading={loadingCurrentMessages} />
                  <div
                    id={`messages_${channelId}_list`}
                    ref={messagesContainerRef}
                    style={{overscrollBehavior: 'contain'}}
                    className='flex flex-col flex-initial relative overflow-y-auto h-full'
                  >
                    <InfiniteScroll
                      ref={infiniteScrollContainerRef}
                      scrollableTarget={`messages_${channelId}_list`}
                      dataLength={messages.length}
                      className='flex text-sm dark:text-white text-jade-950 space-y-2 w-full flex-col-reverse'
                      inverse
                      onScroll={(event) => {
                        setLockToBottom(event.target.scrollTop > -65)
                      }}
                      next={getMoreMessages}
                      hasMore={!allMessagesHaveLoaded}
                      endMessage={(
                        <div className="text-md font-bold py-4 text-center px-4">
                          <div>✅ And that's all she wrote</div>
                        </div>
                      )}
                      loader={<InfinityLoader loading={showLoading} />}
                      height={`${messagesContainerRef.current?.clientHeight}px`}
                    >
                      <>
                        { canScroll ? (
                          <InfinityLoader loading={showLoading} bottom />
                        ) : null }
                        <div ref={messageListBottomRef} id={`message_list_anchor_${channelId}`}></div>
                      </>
                      {
                        startingUnreadMessages && showUnreadMessagePill && newMessagesSeparatorRef.current ? (
                          <div role='button' className='z-50 px-4 shadow-md shadow-gray-800/40 rounded-full mx-auto bg-fuse-orange absolute top-4 flex items-center space-x-2 left-1/2 -translate-x-1/2'>
                            <span onClick={() => scrollToNewMessagesSeparator()} className='py-1.5 h-full whitespace-nowrap'>{startingUnreadMessages} unread</span>
                            <XMarkIcon onClick={() => setShowUnreadMessagePill(false)} className='my-1.5 h-4' />
                          </div>
                        ) : null
                      }
                      {
                        messages.map((message, idx) => {
                          let willRenderNewSep = initNewMessageSepMessageId === message.id;

                          return (
                            <div ref={initNewMessageSepMessageId ? userReadReceiptRef : null} key={`message_container_${message.id}`} className={classNames(`container_${message.id}`)}>
                              <MessageDateSeparator message={message} prevMessage={messages[idx - 1]} />
                              <div ref={initScrollMessageId === message.id ? refCallback : null}>
                                <Message
                                  message={message}
                                  key={`message_${message.id}_${initialScrollComplete ? 'ready' : 'notready'}`}
                                  observe={initialScrollComplete && message.id > maxSeenMessage}
                                  isIntersecting={() => onIntersecting(message)}
                                >
                                  {
                                    readStatuses[message.id] && (
                                      <div className="absolute bottom-3 right-2 flex space-x-1 justify-end">
                                        {
                                          readStatuses[message.id].map((reader) => {
                                            const {avatar_url: avatarUrl, first_name: firstName, last_name: lastName, id} = reader;

                                            return (
                                              <UserAvatar key={`user_${id}_${channelId}_read_receipts`} className='h-4 w-4 rounded-full text-xs' avatarUrl={avatarUrl} firstName={firstName} lastName={lastName} />
                                            );
                                          })
                                        }
                                      </div>
                                    )
                                  }
                                </Message>
                              </div>
                              {
                                willRenderNewSep ? (
                                  <div ref={newMessagesSeparatorRef} className='relative h-[24px] flex flex-col items-center justify-center'>
                                    <div className='absolute bg-gradient-to-r from-green-400 via-green-100 to-green-400 top-2.5 h-[1px] z-40 mt-0.5 w-full' />
                                    <span className='px-2 bg-gray-700 relative z-50 text-green-100'>New</span>
                                  </div>
                                ) : null
                              }
                            </div>
                          )
                        })
                      }
                    </InfiniteScroll>
                  </div>
                  <div className="max-h-48 min-h-20 dark:bg-gray-800 bg-pewter-50 dark:border-t-none border-t border-pewter-200 p-2 flex-auto transition-all w-full">
                    {
                      channelIsArchived ? (
                        <div className="my-5">
                          <div className="min-h-32 dark:text-white text-jade-950 italic w-full text-center">Archived conversation</div>
                          <div className="min-h-32 text-gray-500 italic w-full text-center text-sm">
                            This conversation was archived @ <DateFormatter dateString={channel.archived_at} />
                          </div>
                        </div>
                      ) : null
                    }
                    {
                      channelLocked ? (
                        <div className="my-5">
                          <div className="min-h-32 dark:text-white text-jade-950 italic w-full text-center">You have been removed from this conversation.</div>
                          <div className="min-h-32 text-gray-500 italic w-full text-center text-sm">
                            Conversation available up to <DateFormatter dateString={userMember.removed_from_channel_at} />
                          </div>
                        </div>
                      ) : null
                    }
                    { showEditor ? null : <Editor />}
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </ChannelContext.Provider>
  );
}
