import {EllipsisVerticalIcon, PlusIcon} from "@heroicons/react/24/solid";
import {useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "wouter";
import DashboardContext from "../Contexts/DashboardContext";
import useAppLocation from "../hooks/useAppLocation";
import useSocket from "../hooks/useSocket";
import {setActiveSpace} from "../reducers/spacesSlice";
import {Button} from "../ui/Button";
import {Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger} from "../ui/Dialog";
import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger} from "../ui/Dropdown";
import {classNames} from "../utils/classes";
import LabelForm from "./LabelForm";

function LabelNavLabel({label}) {
  const [showLabelDialog, setShowLabelDialog] = useState(false);
  const {spaces} = useSelector(state => state.spaces);
  const [, navigate] = useLocation();
  const [, {activeSpace, activeLabel}] = useAppLocation();
  const dispatch = useDispatch();
  const [socket] = useSocket();

  function removeLabel() {
    if (!window.confirm('Are you sure you want to remove this label?')) return;

    socket?.emit('channels_users_tags:delete', {tagId: label.id}, () => {
      navigate('/chat/all');
    });
  }

  const activeSpaceUuid = activeSpace?.uuid;
  const {id, name, uuid, color} = label;

  return (
    <li key={`tag_${id}`} className={classNames(activeLabel && activeSpaceUuid === uuid ? 'dark:bg-gradient-to-l dark:bg-transparent bg-mint-100 from-green-500/20 dark:border-y dark:border-green-500/20' : 'dark:hover:bg-gray-600 hover:bg-mint-100', 'group')}>
      <Dialog open={showLabelDialog} onOpenChange={setShowLabelDialog}>
        <DialogContent className='p-5'>
          <DialogHeader>
            <DialogTitle>Edit Label</DialogTitle>
          </DialogHeader>
          <LabelForm label={label} onSave={() => setShowLabelDialog(false)} />
        </DialogContent>
      </Dialog>
      <div className={classNames(activeSpaceUuid === uuid ? 'dark:bg-gradient-to-l dark:bg-transparent bg-mint-100 from-green-500/20 dark:border-y dark:border-green-500/20' : '', 'flex items-center justify-between dark:text-gray-400 text-jade-950 dark:hover:text-white dark:hover:bg-gray-600 hover:bg-mint-100 group p-2 text-sm leading-6 font-semibold')}>
        <div className="flex space-x-2 items-center w-full px-2">
          <span className='h-2 w-2 rounded-full' style={{backgroundColor: `rgb(${color.r} ${color.g} ${color.b}`}}></span>
          <span>{name}</span>
        </div>
        <DropdownMenu>
          <DropdownMenuTrigger onClick={(event) => event.stopPropagation()}>
            <EllipsisVerticalIcon className='h-5 w-5 hidden group-hover:block'/>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => setShowLabelDialog(true)}>Edit</DropdownMenuItem>
            <DropdownMenuItem onClick={removeLabel}>Remove</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </li>
  );
}

export default function Nav({selectConversations = false}) {
  const [showCreateLabelDialog, setShowCreateLabelDialog] = useState(false);
  const {toggleShowSidebar} = useContext(DashboardContext);
  const {user} = useSelector(state => state.user);
  const {spaces} = useSelector(state => state.spaces);
  const [, {activeSpace}] = useAppLocation();
  const [, navigate] = useLocation();
  const dispatch = useDispatch();

  return (
    <ul role="list" className="h-full space-y-4 flex flex-col">
      <>
        <div>
          {
            spaces.map((space) => {
              return (
                <div
                  key={`space_${space.id}`}
                  onClick={() => {
                    if (selectConversations) return;

                    dispatch(setActiveSpace(space));
                    navigate(`/chat/${space.id}`);
                    toggleShowSidebar();
                  }}
                >
                  <li role='button'>
                    <span className={classNames(activeSpace?.id === space.id ? 'dark:bg-gradient-to-l dark:bg-transparent bg-mint-100 from-green-500/20 dark:border-y dark:border-green-500/20' : 'dark:hover:bg-gray-600 hover:bg-mint-100', 'dark:text-gray-400 text-jade-950 dark:hover:text-white group p-3 text-sm leading-6 flex font-semibold items-center')}>
                      <div className="flex items-center justify-between w-full px-4">
                        {space.name}
                      </div>
                    </span>
                  </li>
                </div>
              );
            })
          }
        </div>
        <div>
          <div className='px-6 pr-2 mt-8 mb-2 flex justify-between items-center'>
            <span>Labels</span>
            <Dialog open={showCreateLabelDialog} onOpenChange={setShowCreateLabelDialog}>
              <DialogTrigger asChild>
                <Button variant='ghost' className='px-2'>
                  <PlusIcon className='h-5 w-5'/>
                </Button>
              </DialogTrigger>
              <DialogContent className='p-5'>
                <DialogHeader>
                  <DialogTitle>New Label</DialogTitle>
                </DialogHeader>
                <LabelForm onSave={() => setShowCreateLabelDialog(false)} />
              </DialogContent>
            </Dialog>
          </div>
          {
            user.labels && user.labels.map((tag) => {
              const {id, name, uuid} = tag;
              return (
                <Link
                  key={`labels_${uuid}`}
                  to={`/chat/${uuid}`}
                  onClick={() => {
                    dispatch(setActiveSpace({id, name}));
                    toggleShowSidebar();
                  }}
                >
                  <LabelNavLabel label={tag} />
                </Link>
              );
            })
          }
        </div>
      </>
    </ul>
  );
}
