import {useContext, useEffect, useRef, useState} from "react";
import {Popover, PopoverContent, PopoverTrigger} from "./Popover";
import {Button} from "./Button";
import {MagnifyingGlassIcon, PlusCircleIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {Separator} from "./Separator";
import {Badge} from "./Badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from "./Command";
import {CheckIcon} from "@heroicons/react/20/solid";
import * as React from "react";
import {Column} from "@tanstack/react-table";
import { classNames, cn } from "../utils/classes";
import FilterContext from "../Contexts/FilterContext";
import {useVirtualizer} from "@tanstack/react-virtual";

type FilterOption = {
  label: string
  value: string
  icon?: React.ComponentType<{ className?: string }>
  count?: number
}

interface DataTableFacetedFilterProps<TData, TValue> {
  column?: Column<TData, TValue>
  title?: string
  disabled?: boolean
  options: FilterOption[]
}

export default function DataTableFacetedFilter<TData, TValue>({
  column,
  title,
  options,
  disabled,
  columnName,
}: DataTableFacetedFilterProps<TData, TValue> & {columnName?: string}) {
  const {filterState, setFilterState} = useContext(FilterContext);
  const selectedValues = new Set(filterState[columnName] as string[]);

  function getDisplayValue(value: boolean | string): string {
    if (typeof value === 'boolean') {
      value = value ? 'Yes' : 'No';
    }

    return value;
  }

  function clearFilters() {
    setFilterState({...filterState, [columnName]: []});
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button disabled={disabled} variant="outline" size="sm" className={classNames(selectedValues.size ? 'border-solid' : 'dark:border-dashed border-solid', 'h-7 dark:bg-gray-800 bg-white dark:border-white border-gray-300 ')}>
          <div className='mr-2 group'>
            {
              selectedValues?.size > 0 ? (
                <XMarkIcon className="rounded group-hover:bg-gray-300 group-hover:text-gray-800 h-4 w-4" onClick={(event) => {
                  event.stopPropagation();
                  clearFilters();
                }} />
              ) : (
                <PlusCircleIcon className="h-4 w-4" />
              )
            }
          </div>
          {title}
          {
            selectedValues?.size > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />
                <Badge
                  variant="secondary"
                  className="rounded-sm px-1 font-normal lg:hidden"
                >
                  {selectedValues.size}
                </Badge>
                <div className="hidden space-x-1 lg:flex">
                  {selectedValues.size > 2 ? (
                    <Badge
                      variant="secondary"
                      className="rounded-sm px-1 font-normal"
                    >
                      {selectedValues.size} selected
                    </Badge>
                  ) : (
                    options
                    .filter((option) => selectedValues.has(option.value))
                    .map((option) => {
                      const value = getDisplayValue(option.value);

                      return (
                        <Badge
                          variant="secondary"
                          key={`${title}_${option.value}`}
                          className="rounded-sm px-1 font-normal"
                        >
                          {value}
                        </Badge>
                      )
                    })
                  )}
                </div>
              </>
            )
          }
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[250px] p-0" align="start">
        {
          options.length > 100 ? (
            <VirtList options={options} columnName={columnName} />
          ) : (
            <Command>
              <CommandInput placeholder={title} />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  {options.map((option) => {
                    let {value} = option;
                    const isSelected = selectedValues.has(option.value);

                    value = getDisplayValue(value);

                    return (
                      <CommandItem
                        key={`${columnName}_${option.value}`}
                        onSelect={() => {
                          if (isSelected) {
                            selectedValues.delete(option.value);
                          } else {
                            selectedValues.add(option.value);
                          }

                          setFilterState({...filterState, [columnName]: Array.from(selectedValues)});
                        }}
                      >
                        <div
                          className={cn(
                            "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                            isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible"
                          )}
                        >
                          <CheckIcon className={cn("h-4 w-4")}/>
                        </div>
                        {option.icon && (
                          <option.icon className="mr-2 h-4 w-4 text-muted-foreground"/>
                        )}
                        <span className='truncate' title={value}>{value}</span>
                        <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">{option.count}</span>
                      </CommandItem>
                    )
                  })}
                </CommandGroup>
                {selectedValues.size > 0 && (
                  <>
                    <CommandSeparator/>
                    <CommandGroup className='sticky bottom-0 bg-gray-700/80 font-semibold backdrop-blur-xl'>
                      <CommandItem
                        onSelect={clearFilters}
                        className="justify-center text-center"
                      >
                        Clear filters
                      </CommandItem>
                    </CommandGroup>
                  </>
                )}
              </CommandList>
            </Command>
          )
        }
      </PopoverContent>
    </Popover>
  )
}


function VirtList({options, columnName}: {columnName: string, options: FilterOption[]}) {
  const {filterState, setFilterState} = useContext(FilterContext);
  const [filteredOptions, setFilteredOptions] = useState<FilterOption[]>([]);
  const selectedValues = new Set(filterState[columnName] as string[]);
  const parentRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const rowVirtualizer = useVirtualizer({
    count: filteredOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 30,
  })

  function getDisplayValue(value: boolean | string): string {
    if (typeof value === 'boolean') {
      value = value ? 'Yes' : 'No';
    }

    return value;
  }

  return (
    <>
      <div className="flex items-center border-b px-3">
        <MagnifyingGlassIcon className="mr-2 h-4 w-4 shrink-0 opacity-50"/>
        <input
          onChange={(event) => {
            setFilteredOptions(options.filter(o => event.target.value ? o.value.toLowerCase().includes(event.target.value.toLowerCase()) : true));
          }}
          className="flex h-8 w-full focus:border-none rounded-md bg-transparent my-2 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
        />
      </div>
      <div>
        {
          filteredOptions.length ? null : <div className='p-3 text-center'>No options found</div>
        }
        <div ref={parentRef} style={{maxHeight: '400px', overflow: 'auto'}}>
          <div
            id='virtlist'
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {rowVirtualizer.getVirtualItems().map((item) => {
              const option = filteredOptions[item.index];

              let {value} = option;
              const isSelected = selectedValues.has(option.value);

              value = getDisplayValue(value);

              return (
                <div
                  key={item.index}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: `${item.size}px`,
                    transform: `translateY(${item.start}px)`,
                  }}
                >
                  <div
                    key={`${columnName}_${option.value}`}
                    className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none data-[disabled=true]:pointer-events-none data-[selected=true]:bg-accent data-[selected=true]:text-accent-foreground data-[disabled=true]:opacity-50"
                    onClick={() => {
                      if (isSelected) {
                        selectedValues.delete(value);
                      } else {
                        selectedValues.add(value);
                      }

                      setFilterState({...filterState, [columnName]: Array.from(selectedValues)});
                    }}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")}/>
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground"/>
                    )}
                    <span className='truncate' title={value}>{value}</span>
                    <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">{option.count}</span>
                  </div>
                </div>
              )
            })}
          </div>
          <div role='button' onClick={() => setFilterState({...filterState, [columnName]: []})} className='p-2 sticky bottom-0 bg-gray-800 backdrop-blur-xl bg-opacity-90 text-center rounded-b'>Clear filter</div>
        </div>
      </div>
    </>
  )
}
